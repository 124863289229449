import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import styles from "./appcard.module.css"

const AppCard = ({ linkName, appImage, appTitle, appDescription }) => {
  const appIconImageData = useStaticQuery(graphql`
    query {
      allFile(filter: { internal: { mediaType: { regex: "images/" } } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const match = useMemo(
    () =>
    appIconImageData.allFile.edges.find(
        ({ node }) => appImage === node.relativePath
      ),
    [appIconImageData, appImage]
  )

  return (
    <div className={styles.container}>
      <Link to={"/apps/" + linkName}>
        <Image
          className={styles.image}
          fluid={match.node.childImageSharp.fluid}
          alt="App image"
        />
        <h3 className={styles.title}>{appTitle}</h3>
        <p className={styles.text}>{appDescription}</p>
      </Link>
    </div>
  )
}

AppCard.propTypes = {
  linkName: PropTypes.string,
  appImage: PropTypes.string,
  appTitle: PropTypes.string,
  appDescription: PropTypes.string
}

AppCard.defaultProps = {
  linkName: "",
  appImage: "",
  appTitle: "",
  appDescription: ""
}

export default AppCard
