import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import AppCard from "../components/appcard/appcard"

const AppsPage = () => (
  <Layout pageHeaderText="Games & Apps" pageHeaderImage="forest.png">
    <SEO title="Games and Apps" />
    <div style={{
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "auto",
      gridColumnGap: "3rem",
      gridRowGap: "1rem",
      marginBottom: "1rem",
      marginTop: "2rem",
      justifyItems: "center"
    }}>
      <AppCard linkName="blink" appTitle="Blink" appImage="blink-icon.png" appDescription="My first mobile game for iOS and Android, built with Unity."/>
      <AppCard linkName="savesense" appTitle="SaveSense" appImage="savesense-icon.png" appDescription="An iOS app designed to help you create smart purchasing habits."/>
      <AppCard linkName="snowpounce" appTitle="Snow Pounce" appImage="snowpounce-icon.png" appDescription="A mobile game about an Arctic Fox. Made from scratch in 2 weeks!"/>
    </div>
    
  </Layout>
)

export default AppsPage